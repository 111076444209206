var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "div",
        { staticClass: "pa-2" },
        [
          _c("h5", { staticClass: "text-uppercase" }, [
            _vm._v(_vm._s(_vm.$t("profilePrivacy.title")))
          ]),
          _c("div", [_vm._v(_vm._s(_vm.$t("profilePrivacy.description")))]),
          _c(
            "v-btn",
            {
              staticClass: "my-4",
              attrs: {
                block: "",
                large: "",
                rounded: "",
                depressed: "",
                color: "primary"
              },
              on: {
                click: function($event) {
                  return _vm.openInBrowser(
                    "https://www.galasupermercati.it/privacy"
                  )
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("profilePrivacy.title")))]
          )
        ],
        1
      ),
      _c("PrivacySection", {
        attrs: { userData: _vm.userData },
        on: {
          "update:userData": function($event) {
            _vm.userData = $event
          },
          "update:user-data": function($event) {
            _vm.userData = $event
          }
        }
      }),
      _c("ResponseMessage", {
        staticClass: "mt-3",
        attrs: { response: _vm.response }
      }),
      _c(
        "div",
        { staticClass: "px-2 py-3" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                depressed: "",
                block: "",
                rounded: "",
                color: "primary",
                large: ""
              },
              on: { click: _vm.updatePrivacy }
            },
            [_vm._v(_vm._s(_vm.$t("common.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }